.endless-racer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000dd; /* Semi-transparent black */
    z-index: 1000; /* On top of other content */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
  }
  
  .close-button {
    margin: 10px;
    background-color: #000000;
    color: #fed801;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .score {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .game-area {
    position: relative;
    width: 80%;
    height: 500px;
    background-color: #333333;
    display: flex;
    border: 2px solid #ffffff;
  }
  
  .lane {
    flex: 1;
    border-left: 1px solid #555555;
    position: relative;
  }
  
  .lane:first-child {
    border-left: none;
  }
  
  .lambo {
    position: absolute;
    bottom: 0;
    width: 50px;
    left: calc(50% - 25px);
  }
  
  .rolex {
    position: absolute;
    width: 30px;
    left: calc(50% - 15px);
  }
  