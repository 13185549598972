/* src/App.css */

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1s infinite;
}

body {
  /*background-image: url('https://your-image-url.com/degen-background.jpg');*/
  background-size: cover;
  color: #ffffff;
}

.navbar, .bg-dark {
  background-color: transparent !important;
}

.btn-primary {
  background-color: #ff00ff;
  border-color: #ff00ff;
}

.alert-success {
  background-color: #00ff00;
  border-color: #00ff00;
  color: #000000;
}

.alert-danger {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #ffffff;
}

.card {
  border: 2px solid #ffffff;
}

.card-title {
  font-size: 1.5rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li::before {
  content: '🔥 ';
}

/* Update button styles */
.btn-primary {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #fed801 !important;
}


/* Loading screen styles */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.loading-logo {
  animation: spin 2s linear infinite, pulse 1.5s infinite;
  width: 200px;
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}



.falling-objects {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allow clicks through the falling objects */
  overflow: hidden;
  z-index: 2; /* Place behind other content */
}

/* Individual falling object */
.falling-object {
  position: absolute;
  top: -100px; /* Start slightly above the viewport */
  animation-name: fall;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes fall {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(110vh) rotate(360deg);
  }
}

.main-content {
  position: relative;
  z-index: 1;
}